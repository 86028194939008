.ap-navigation.ap-header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.page-position-bar {
  background-color: var(--chakra-colors-custom-light-accent);
  height: 2px;
}

.pagePositionWrapper {
  width: 100%;
}

.ap-navigation {
  position: absolute;
  width: 100%;
  padding: 10px;
}
