.carousel-item {
  height: 205px;
  background-size: cover;
  background-position: center center;
}

.tm_Card {
  flex-wrap: wrap;
  flex-direction: column;
}

.tm-Name {
  padding-left: 5px;
  font-weight: 600;
  font-style: italic;
}

.tm-Text {
  font-size: 17px;
  color: var(--background-color-primary);
  max-width: 730px;
}

.tm-Slice {
  background-color: var(--chakra-colors-custom-light-background);
}

.tm-Person {
  float: right;
  color: var(--background-color-secondary);
  font-size: 15px;
  padding: 10px;
}

.carousel-indicators li {
  background-color: var(--background-color-primary);
}

.carousel-indicators .active {
  background-color: var(--white-text);
}

.testimonial-container {
  transition: all 2s linear;
}
