.me-Card_body {
  width: 300px;
  min-width: 300px;
  height: 350px;
  margin-right: 25px;
  padding: 10px;
  background-color: var(--background-color-secondary);
  border: solid 1px #00000057;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.45);
}

.me-Card_description {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
}

.me-Card_date {
  color: white;
  font-size: 14px;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.me-Card_title {
  color: var(---chakra-colors-custom-light-background);
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}

.me-Card_logo {
  /* background-size: contain; */
  width: 75px;
}

.me-Card_divider {
  background-color: #ffffff82;
}

.me-Card_header {
  display: flex;
  height: 80px;
  font-family: "Roboto", sans-serif;
  justify-content: center;
  align-items: center;
}

.me-Card_headerColumn {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.me-Card_organiserName {
  color: white;
  font-family: "Roboto", sans-serif;
  margin: 0;
}

.me-Wrapper {
  width: 100%;
  overflow: scroll;
  height: 415px;
}

.me-Slice {
  background-image: linear-gradient(#252424, #3c3a3a);
}

.me-Slice .site-Subheader {
  color: white;
}
.me-Slice .site-Subheader:after,
.me-Slice .site-Subheader:before {
  background-color: salmon;
}

.me-Paragraph_black {
  color: white;
}

.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
}
.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}
.react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}
.react-multiple-carousel__arrow::before {
  font-size: 20px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}
.react-multiple-carousel__arrow--left {
  left: calc(4% + 1px);
}
.react-multiple-carousel__arrow--left::before {
  content: "\e824";
}
.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px);
}
.react-multiple-carousel__arrow--right::before {
  content: "\e825";
}
.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.react-multi-carousel-dot button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background 0.5s;
  border-width: 2px;
  border-style: solid;
  border-color: grey;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
}
.react-multi-carousel-dot button:hover:active {
  background: #080808;
}
.react-multi-carousel-dot--active button {
  background: #080808;
}
.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }
  .react-multi-carousel-track {
    overflow: visible !important;
  }
}
