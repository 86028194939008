@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900&display=swap");

:root {
  --navigation-height: 65px;

  --neo-light-top-left-pull: -6px -6px 20px var(--chakra-colors-custom-light-neo-lightborder),
    6px 6px 20px var(--chakra-colors-custom-light-neo-darkborder);

  --neo-light-bottom-right-push: inset -6px -6px 20px
      var(--chakra-colors-custom-light-neo-lightborder),
    inset 6px 6px 20px var(--chakra-colors-custom-light-neo-darkborder);

  --neo-dark-top-left-pull: -6px -6px 20px var(--chakra-colors-custom-dark-neo-lightborder),
    6px 6px 20px var(--chakra-colors-custom-dark-neo-darkborder);

  --neo-dark-bottom-right-push: inset -6px -6px 20px
      var(--chakra-colors-custom-dark-neo-lightborder),
    inset 6px 6px 20px var(--chakra-colors-custom-dark-neo-darkborder);

  --slice-margin: 100px;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: inherit;
  text-decoration: none;
}

a * :hover {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  background-color: var(--chakra-colors-custom-light-background);
  color: var(--chakra-colors-custom-light-accent);
}

.chakra-ui-dark * {
  background-color: var(--chakra-colors-custom-dark-background);
}

.neo-box.pull.fuzzy {
  box-shadow: inset 3px 3px 4px 0 #fff, -3px -3px 4px 0 #fff,
    inset -6px -6px 12px -4px rgba(0, 0, 0, 0.1),
    8px 8px 16px -4px rgba(0, 0, 0, 0.1);
  border: none;
}

.neo-box.push.fuzzy {
  box-shadow: inset 3px 3px 4px 0 #fff, -3px -3px 4px 0 #fff,
    inset -6px -6px 12px -4px rgba(0, 0, 0, 0.1),
    8px 8px 16px -4px rgba(0, 0, 0, 0.1);
  border: none;
}

.neo-box {
  border: solid 1px rgb(0 0 0 / 2.5%);
}

.neo-box-paragraph-padding {
  border-radius: 40px;
  padding: 30px;
  margin: 30px;
}

.neo-box.pull {
  box-shadow: var(--neo-light-top-left-pull);
}

.chakra-ui-dark .neo-box.pull {
  box-shadow: var(--neo-dark-top-left-pull);
}

.neo-box.pull.hover:hover {
  box-shadow: var(--neo-light-bottom-right-push);
}

.chakra-ui-dark .neo-box.pull.hover:hover {
  box-shadow: var(--neo-dark-bottom-right-push);
}

.neo-box.push {
  box-shadow: var(--neo-light-bottom-right-push);
}

.chakra-ui-dark .neo-box.push {
  box-shadow: var(--neo-dark-bottom-right-push);
}

.neo-box.push.hover:hover {
  box-shadow: var(--neo-light-top-left-pull);
}

.chakra-ui-dark .neo-box.push.hover:hover {
  box-shadow: var(--neo-dark-top-left-pull);
}

.site-Subheader {
  font-size: 27px;
  text-align: center;
  text-decoration: none;
  padding: 10px 20px 20px 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
}

.site-Subheader::before,
.site-Subheader::after {
  position: absolute;
  top: 38%;
  overflow: hidden;
  width: 20%;
  margin: 0 5%;
  height: 1px;
  background-color: salmon;
  content: "\a0";
}

.site-Subheader::before {
  margin-left: -25%;
  padding-right: 10%;
  text-align: right;
}

.ap-site-slice {
  margin-top: var(--slice-margin);
}

.site-html {
  background-color: salmon;
}
