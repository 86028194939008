.pr-Slice {
  padding: 15px;
}

.pc-Container {
  border: solid salmon 1px;
  margin-bottom: 25px;
  transition: 1s ease-in-out max-height;
  max-height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pc-Container_game {
  background-color: black;
  padding: 15px;
  margin: 20px;
}

.pc-Container_expanded {
  max-height: 1000px;
}

.pc-Container_paragraph {
  padding: 10px;
}

.pc-Container_header {
  font-size: 25px;
  line-height: 25px;
}

.pc-Container_icon {
  color: salmon;
  width: 75px;
}

.pc-Container_link {
  color: salmon;
}

.pc-Container_emoticon {
  width: 75px;
  height: 75px;
}
.pc-Container_emoticon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: black solid 1px;
}

.pc-Container_PreviewImage {
  max-width: 400px;
  margin: 20px 0;
}
.pc-Container_PreviewImage img {
  width: 100%;
  border: black solid 1px;
}

.pc-HeaderContainer {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: Center;
  justify-content: space-between;
}

.pc-Container_subHeading {
  color: salmon;
  font-size: 19px;
  font-weight: 600;
}

.pc-Container_techSection {
  display: flex;
  flex-direction: row;

  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.pc-Container_gameCanvas {
  border: solid 1px white;
}
