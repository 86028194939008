.em-Wrapper {
  border: solid 1px black;
  border-radius: 10px;
  background-color: var(--background-color-tertiary);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  transition: all 1s ease;
}

.em-Wrapper:hover {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.75);
  transform: scale(1.05);
}

.em-Employee {
  color: $accent-color-primary;
  font-size: 24px;
  line-height: 30px;
  transition: all 1s ease;
}

.em-Wrapper:hover .em-Employee {
  font-size: 26px;
}

.em-Wrapper .site-Paragraph {
  max-width: 500px;
}

.em_Jobtitle {
  color: $accent-color-secondary;
  font-size: 15px;
}

.em-Period {
  font-style: italic;
  font-weight: 600;
  color: $accent-color-tertiary;
  font-size: 13px;
}

.em-Logo {
  max-width: 220px;
}

.em-Container {
  padding-bottom: 5%;
}

.em-WebsiteImage_content {
  width: 100%;
  padding: 20px 20px 20px 20px;
  transition: all 1s ease;
}

.em-WebsiteImage_container {
  max-width: 400px;
}

.em-HeaderContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: row;
}

.em-Wrapper_odd .em-HeaderContainer {
  flex-direction: row-reverse;
}

.em-HeaderContainer_text {
  align-items: center;
}

@media (max-width: 576px) {
  .em-HeaderContainer {
    flex-direction: column;
  }

  .em-Wrapper_odd .em-HeaderContainer {
    flex-direction: column-reverse;
  }
}

.em-CardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.em-BodyContainer {
  display: flex;
  flex-direction: row;

  justify-content: space-around;
  align-items: center;
}

.em-Wrapper_odd .em-BodyContainer {
  flex-direction: row-reverse;
}

@media (max-width: 576px) {
  .em-BodyContainer {
    flex-direction: column;
  }

  .em-Wrapper_odd .em-BodyContainer {
    flex-direction: column;
  }
}

@keyframes em-Employment_hover {
  from {
    border: solid 1px black;
  }
  to {
    border: solid 1px salmon;
  }
}

.employment-image {
  border-radius: 7px;
  border: solid white;
  background-color: white;
  padding: 5px;
}
