.footerIcon {
  margin-left: 10px;
}

.footer-slice {
  background-color: var(--background-color-tertiary);
  color: var(--white-color);
  display: block;
}

.footer-button {
  width: 35px;
  border-radius: 10px;
  margin: 5px 0 5px 5px;
}

.button-container span {
  font-size: 13px;
}

.name-stamp {
  float: left;
}
