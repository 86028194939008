.sk-Icon {
  width: 100%;
  height: 100%;
}

.sk-Container {
  font-size: 45px;
}

.sk-Wrapper {
  background-color: var(--chakra-colors-custom-light-background);
  padding: 40px 0;
  border-radius: 25px;
  margin: 7px;
}
