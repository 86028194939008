.hi-Text_wrapper {
  background-color: var(--opaque-background-color);
  color: var(--white-color);
  font-size: 350%;
  border-radius: 40px;
  border: solid 2px #fa80727a;
}

.hi-Text_content {
  font-size: 2rem;
}

.hi-Image_wrapper {
  text-align: center;
  position: relative;
}

.hi-Image_text {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--white-text);
  height: 100px;
}

.hi-Image_background {
  width: 100%;
  height: 600px;
}

.hi-Image_background.hi-Image_background-webp {
  /* background-image: url(../../assets/webp/intro-image-mid.webp); */
  background-size: cover;
  background-position: center;
}

.hi-Image_background.hi-Image_background-nowebp {
  /* background-image: url(../../assets/jpeg/intro-image-mid.jpg); */
  background-size: cover;
  background-position: center;
}

@media (min-width: 48em) {
  .hi-Image_background {
    height: 800px;
    background-size: cover;
    background-position: center;
  }
}
